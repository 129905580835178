import React from 'react';
import {token_pattern} from "../../config";


function Token(props) {
    return (
        <input type='password' ref={props.refr} name="token" required pattern={token_pattern}
               placeholder={props.placeholder} autoComplete="off" />
    );
}

export default Token;