import {Navigate, Route, Routes} from "react-router";
import React, {Component} from 'react';
import Login from "../Pages/Login";
import Cabinet from "../Pages/Cabinet";
import APIpost from "../base"
import Cookies from 'universal-cookie';
import Notifications from "./Notifications";
import axios from "axios";


axios.defaults.timeout = 100
let canBeCheckedDeps = true

class URLRoutes extends Component {
    constructor(_) {
        super(_);

        this.notifs = React.createRef()

        this.check_deps = this.check_deps.bind(this)
        this.get_new_cookies = this.get_new_cookies.bind(this)
    }

    componentDidMount() {
        if (canBeCheckedDeps) {
            this.check_deps()
            canBeCheckedDeps = !canBeCheckedDeps
        }
    }

    get_new_cookies() {
        const cookies = new Cookies();

        APIpost("gnct/", {}, "BestAccounts").then(req => {
            cookies.set('cursessid', req.data['cookies'], {'maxAge': 3600 * 24 * 30 * 3, 'path': '/', 'secure': true});
            localStorage.setItem("sltcks", req.data['sltcks'])
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
    }

    check_deps() {
        const cookies = new Cookies();

        if (cookies.get('cursessid') && localStorage.getItem('sltcks')) {
            APIpost("chkvlcks/", {
                "cookiess": cookies.get('cursessid'),
                "ckksst": localStorage.getItem('sltcks')
            }, "BestAccounts")
                .then(req => {
                    if (req.data['RESP'] === 0) {
                        this.get_new_cookies()
                    }
                })
                .catch(e => {
                    if (e.response.data['RESP'] === -1) {
                        this.notifs.current.error_handler(e.response)
                    } else {
                        this.notifs.current.unhandled_error_handler(e)
                    }
                })
        } else {
            this.get_new_cookies()
        }
    }

    render() {
        return (
            <>
                <Notifications ref={this.notifs}/>
                <Routes>
                    <Route path='/' element={<Navigate notifs={this.notifs} to="/login"/>}/>
                    <Route path='/login' element={<Login notifs={this.notifs}/>}/>
                    <Route path='/login/auth' element={<Login auth={true} notifs={this.notifs}/>}/>
                    <Route path='/cabinet' element={<Cabinet notifs={this.notifs}/>}/>
                    {/*<Route component={NotFoundSection} path='*' />*/}
                </Routes>
            </>
        );
    }
}

export default URLRoutes;