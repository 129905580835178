import React from 'react';
import {login_pattern} from '../../config'


function LoginInp(props) {
    return (
        <>
            <h6>Только цифры и латинские буквы (5-35 символов)</h6>
            <input onChange={props.onChange} ref={props.refr} name="login" required type="text" inputMode="verbatim"
                   pattern={login_pattern} placeholder={props.placeholder} autoComplete={props.autoComplete_} />
        </>
    );
}

export default LoginInp;