import axios from "axios";
import backend_url from "./config"


async function APIpost(url, postcontent, service) {
    axios.defaults.timeout = 8500

    let apikey = await axios.post(`${backend_url}/gnak/`)
        .then(newapikey => newapikey.data)

    try {
        return axios.post(`${backend_url}/${url}`, {
            "ka": apikey['ak'], ...postcontent, 'service': service
        })
    } catch (err) {
        if (err.code === "ECONNABORTED") {
            return APIpost(url, postcontent, service)
        }
    }
}

export default APIpost