import React, {Component} from 'react';


class Mailing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: false
        }
        this.refr = props.refr
        this.placeholder = props.placeholder

        this.switch_state = this.switch_state.bind(this)
    }

    switch_state() {
        this.setState({
            value: !this.state.value
        })
    }

    render() {
        return (
            <input onClick={this.switch_state} value={this.state.value} ref={this.refr} name="mailing"
                   type="checkbox" placeholder={this.placeholder}/>
        )
    }
}

export default Mailing;