import React from 'react';
import {name_pattern} from "../../config";

function Name(props) {
    return (
        <>
            <h6>Только кириллица и латинские буквы (2-30 символов)</h6>
            <input onChange={props.onChange} ref={props.refr} inputMode="" name="name" required type="text"
                   pattern={name_pattern} placeholder={props.placeholder} autoComplete={props.autoComplete_?props.autoComplete_:'off'} />
        </>
    );
}

export default Name;