import React, {Component} from 'react';
import "../scss/notifs.scss"

class Notification extends Component {
    constructor(props) {
        super(props);

        this.progressbar = React.createRef()

        this.state = {
            rem_func: props.rem_func,
            cur_id: props.id,
            type: props.type,
            message: props.message,
            submessage: props.submessage,
            to_rem: props.to_rem,
            start_time: props.time,
            time: props.time,
            extra_link: props.extra_link,
            link_type: props.link_type,
            progress: 1
        }

        if (props.error) {
            if (typeof props.error === "string")
                this.state['error'] = props.error.message
        }

        this.cur_type = ""

        if (this.state.time !== -1) {
            this.cur_type = this.cur_type + "-time"
        }
        if (this.state.extra_link !== '' && this.state.extra_link !== undefined) {
            this.cur_type = this.cur_type + "-extra_link"
        }
        if (this.state.error !== '' && this.state.error !== undefined) {
            this.cur_type = this.cur_type + "-error"
        }

        this.self_remove = this.self_remove.bind(this)
    }

    componentDidMount() {
        if (this.state.time !== -1) {
            let intervalDel = setInterval(() => {
                if (this.state.deleted) {
                    clearInterval(intervalDel)
                    return
                }

                if (this.state.time <= 0) {
                    this.self_remove()
                    clearInterval(intervalDel)
                    return
                }

                this.setState({
                    progress: this.state.time / this.state.start_time,
                    time: this.state.time - 10
                })
            }, 10);
        }
    }

    self_remove() {
        this.state.rem_func(this.state.cur_id)

        this.setState({
            to_rem: true,
            deleted: true
        })
    }

    render() {
        return (
            <div id={this.state.cur_id}
                 className={`notification${this.cur_type} notif-${this.state.type} ${this.state.to_rem ? 'remove_animate' : ''}`}>
                <span className="notif-message">{this.state.message}</span>
                <span className="notif-submessage">{this.state.submessage}</span>
                <button onClick={this.self_remove} className="notif-crosses">
                    <span className="notif-cross"></span>
                    <span className="notif-cross"></span>
                </button>
                {this.state.extra_link ? <a href={`${this.state.link_type}${this.state.extra_link}`}
                                            className="notif-extra_link">{this.state.extra_link}</a> : ''}
                {this.state.error ? <span title="Текст ошибки" className='notif-error'>{this.state.error}</span> : ''}
                {this.state.time >= 0 ? <div style={{width: `${this.state.progress * 100}%`}} ref={this.progressbar}
                                             className="notif-progress"/> : ''}
            </div>
        );
    }
}

export default Notification;