import React, {Component} from 'react';
import visible from '../../Assets/Visible.png'
import invisible from '../../Assets/Invisible.png'
import {password_pattern} from "../../config";


class Password extends Component {
    constructor(props) {
        super(props);

        if (props.refr) {
            this.refr = props.refr
        } else {
            this.refr = React.createRef()
        }
        this.imgvis = React.createRef()

        this.state = {
            imgsrc: visible
        }

        this.onChange = props.onChange
        this.onComplete = props.autoComplete_
        this.placeholder = props.placeholder

        this.show_unshow = this.show_unshow.bind(this)
    }

    show_unshow() {
        if (this.refr.current.type === 'text') {
            this.refr.current.type = 'password'
            this.setState({
                imgsrc: visible
            })
        } else {
            this.refr.current.type = 'text'
            this.setState({
                imgsrc: invisible
            })
        }
    }

    render() {
        return (
            <>
                <h6>Только цифры и латинские буквы (8-44 символов)</h6>
                <input style={{marginBottom: '4px'}} onChange={this.onChange} ref={this.refr} name="password" required
                       type="password" inputMode="verbatim" pattern={password_pattern}
                       placeholder={this.placeholder} autoComplete={this.onComplete?this.onComplete:'off'} />
                <img ref={this.imgvis} id="password_visibility" alt='Переключение видимости пароля'
                     onClick={this.show_unshow} src={this.state.imgsrc}/>
            </>
        )
    }
}

export default Password;