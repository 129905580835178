import axios from "axios";


export let login_pattern = "[A-Za-z0-9]{5,35}";
export let name_pattern = "[А-Яа-яA-Za-z]{2,30}";
export let password_pattern = "[A-Za-z0-9]{8,44}";
export let token_pattern = "[A-Za-z0-9]{140}$";


let futreq = axios.create()
futreq.defaults.timeout = 20

const backend_url = await futreq.get(`http://127.0.0.1:7655/chk/`)
    .then(req => req.status === 200?"http://127.0.0.1:7655":'https://api.bestaccounts.ru')
    .catch(_ => 'https://api.bestaccounts.ru')


export default backend_url;
