import React, {Component} from 'react';
import Notification from "./Notification";
import "../scss/notifs.scss"


class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifs: {},
            count: 0
        }

        this.add_notif = this.add_notif.bind(this)
        this.rem_notif = this.rem_notif.bind(this)
        this.unhandled_error_handler = this.unhandled_error_handler.bind(this)
        this.error_handler = this.error_handler.bind(this)
    }

    unhandled_error_handler(e) {
        if (e.message === "Network Error") {
            this.add_notif('critical', `Ошибка подключения`, `Возможно, у вас проблемы с подключением к интернету или сервисы BestDevelopment недоступны. Пожалуйста, повторите попытку позже. Или напишите нам на почту`, 150000, 'bestdevelopment.work@gmail.com', "mailto:")
        } else if (e.code === "ECONNABORTED") {
            this.add_notif('critical', `Таймаут подключения`, `Возможно, у вас проблемы с подключением к интернету или сервисы BestDevelopment недоступны. Пожалуйста, повторите попытку позже. Или напишите нам на почту`, 150000, 'bestdevelopment.work@gmail.com', "mailto:")
        } else {
            this.add_notif('critical', `Произошла неизвестная ошибка`, `Пожалуйста, повторите попытку позже. Или напишите нам на почту`, 15000, 'bestdevelopment.work@gmail.com', "mailto:", e)
        }
    }

    error_handler(req) {
        if (req.data['ERROR']) {
            let error = req.data['ERROR']['reasons']['russian']
            this.add_notif('critical', `Ошибка ${req.data['ERROR']['code']}`, error, 10000)
        } else if (req.data['UNHANDLED_ERROR']) {
            let error = req.data['UNHANDLED_ERROR']['reasons']['russian']
            this.add_notif('critical', `Ошибка ${req.data['UNHANDLED_ERROR']['code']}`, error, 30000, 'bestdevelopment.work@gmail.com', "mailto:")
        } else {
            console.log(`Unknown Error type - ${req.data}`)
        }
    }

    rem_notif(id) {
        this.setState({
            notifs: {
                ...this.state.notifs,
                [id]: {...this.state.notifs[id], "to_rem": true}
            }
        })

        setTimeout(function () {
            delete this.state.notifs[id]

            this.setState({
                notifs: this.state.notifs
            })
        }.bind(this), 250);
    }

    add_notif(type, message, submessage, mstime, extra_link, link_type, error) {
        this.setState({
            count: this.state.count + 1,
            notifs: {
                ...this.state.notifs, [this.state.count]: {
                    'type': type,
                    'message': message,
                    "submessage": submessage,
                    'to_rem': false,
                    'time': mstime ? mstime : -1,
                    'extra_link': extra_link,
                    'link_type': link_type,
                    'error': error,
                }
            }
        })
    }

    render() {
        return (
            <div id="notif_div">
                {Object.keys(this.state.notifs).map((key, _) =>
                    <Notification
                        id={key} key={key}
                        to_rem={this.state.notifs[key]['to_rem']}
                        type={this.state.notifs[key]['type']}
                        message={this.state.notifs[key]['message']}
                        submessage={this.state.notifs[key]['submessage']}
                        time={this.state.notifs[key]['time']}
                        extra_link={this.state.notifs[key]['extra_link']}
                        link_type={this.state.notifs[key]['link_type']}
                        error={this.state.notifs[key]['error']}
                        rem_func={this.rem_notif}
                    />)}
            </div>
        );
    }
}

export default Notifications;