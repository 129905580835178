import React, {Component} from 'react';
import Password from "../Components/Inputs/Password";
import LoginInp from "../Components/Inputs/LoginInp";
import Token from "../Components/Inputs/Token";
import Name from "../Components/Inputs/Name";
import Mail from "../Components/Inputs/Mail";
import Mailing from "../Components/Inputs/Mailing";
import APIpost from "../base"
import Cookies from 'universal-cookie';
import "../scss/mailverify.scss"


let canBeCheckedAcc = true


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passcondtext: ''
        }

        this.formreg_valid = false
        this.loginreg_valid = false
        this.pass_valid = false

        this.notifs = props.notifs

        // this.captcharef = React.createRef()
        this.tokentokenpost = React.createRef()
        this.passloginpost = React.createRef()
        this.loginloginpost = React.createRef()
        this.nameregpost = React.createRef()
        this.loginregpost = React.createRef()
        this.passregpost = React.createRef()
        this.mailregpost = React.createRef()
        this.mailingregpost = React.createRef()
        this.tokenblock = React.createRef()
        this.loginblock = React.createRef()
        this.registerblock = React.createRef()
        this.tokenblockbutton = React.createRef()
        this.loginblockbutton = React.createRef()
        this.registerblockbutton = React.createRef()
        this.registerbutton = React.createRef()
        this.loginbutton = React.createRef()
        this.tokenbutton = React.createRef()
        this.verifymailpage = React.createRef()
        this.loginpage = React.createRef()
        this.passcond = React.createRef()

        this.postreg = this.postreg.bind(this)
        this.form_valid = this.form_valid.bind(this)
        this.login_valid = this.login_valid.bind(this)
        this.switch_token = this.switch_token.bind(this)
        this.switch_login = this.switch_login.bind(this)
        this.switch_register = this.switch_register.bind(this)
        this.pass_check = this.pass_check.bind(this)
        this.acc_exists = this.acc_exists.bind(this)
        this.login = this.login.bind(this)
        this.token_auth = this.token_auth.bind(this)
    }

    login(e) {
        e.preventDefault()

        if (!this.loginreg_valid) {
            this.notifs.current.add_notif("warning", "Некорректная форма", "Данные, введённые в поля некорректны или имеют неверный формат", 6500)
            return
        }
        this.loginbutton.current.disabled = true

        const cookies = new Cookies()

        APIpost('la/', {
            "login": this.loginloginpost.current.value,
            "password": this.passloginpost.current.value,
            "cookiess": cookies.get('cursessid'),
            "ckksst": localStorage.getItem("sltcks"),
        }, "BestAccounts").then(req => {
            if (req.data['RESP'] === -1) {
                this.notifs.current.error_handler(req)
            } else if (req.data['RESP'] === 0) {
                this.notifs.current.add_notif('critical', 'Неуспешный вход', 'Введённые вами логин или пароль неверны. Попробуйте снова', 6500)
                // this.notifs.current.add_notif('critical', 'Неуспешный вход', 'Введённые вами логин или пароль неверны. Попробуйте снова или нажмите "Я забыл пароль/логин"', 6500)
            } else {
                window.location.href = '/cabinet'
            }
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
        this.loginbutton.current.disabled = false
    }

    token_auth(e) {
        e.preventDefault()

        if (!this.tokentokenpost.current.checkValidity()) {
            this.notifs.current.add_notif("warning", "Некорректная форма", "Данные, введённые в поля некорректны или имеют неверный формат", 6500)
            return
        }
        this.tokenbutton.current.disabled = true

        const cookies = new Cookies()

        APIpost('ta/', {
            "token": this.tokentokenpost.current.value,
            "cookiess": cookies.get('cursessid'),
            "ckksst": localStorage.getItem("sltcks"),
        }, "BestAccounts").then(req => {
            if (req.data['RESP'] === -1) {
                this.notifs.current.error_handler(req)
            } else if (req.data['RESP'] === 0) {
                this.notifs.current.add_notif('critical', 'Неуспешный вход', 'Введённый вами токен неверен или недействителен. Попробуйте войти снова или через логин и пароль', 7500)
            } else {
                window.location.href = '/cabinet'
            }
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
        this.tokenbutton.current.disabled = false
    }

    form_valid() {
        const bools_valids = [
            this.nameregpost.current.checkValidity(),
            this.loginregpost.current.checkValidity(),
            this.passregpost.current.checkValidity(),
            this.mailregpost.current.checkValidity(),
        ]
        this.formreg_valid = Object.values(bools_valids).every(item => item === true) && this.pass_valid
    }

    login_valid() {
        const bools_valids = [
            this.loginloginpost.current.checkValidity(),
            this.passloginpost.current.checkValidity(),
        ]
        this.loginreg_valid = Object.values(bools_valids).every(item => item === true)
    }

    postreg(e) {
        e.preventDefault()

        // if (!(this.formreg_valid && this.captcharef.current.state.STATUS === 'SUCCESS')) {
        //     if (!this.formreg_valid) {
        //         this.notifs.current.add_notif("warning", "Некорректная форма", "Данные, введённые в поля некорректны или имеют неверный формат", 6500)
        //     } else if (this.captcharef.current.state.STATUS === 'NOT_VERIFIED') {
        //         this.notifs.current.add_notif("warning", "Капча не пройдена", 'Сначала пройдите капчу, нажав на кнопку "Я не робот"', 6500)
        //     } else if (this.captcharef.current.state.STATUS === 'FAILED') {
        //         this.notifs.current.add_notif("critical", "Капча не пройдена", 'У вас не получилось пройти капчу, вы робот? Попробуйте позже', 6500)
        //     }
        //     return
        // }

        if (!this.formreg_valid) {
            this.notifs.current.add_notif("warning", "Некорректная форма", "Данные, введённые в поля некорректны или имеют неверный формат", 6500)
        }
        this.registerbutton.current.disabled = true

        const cookies = new Cookies()

        APIpost("rnap/", {
            "login": this.loginregpost.current.value,
            "password": this.passregpost.current.value,
            "name": this.nameregpost.current.value,
            "mail": this.mailregpost.current.value,
            "mailing": this.mailingregpost.current.value,
            "cookiess": cookies.get('cursessid'),
            "ckksst": localStorage.getItem("sltcks"),
        }, "BestAccounts").then(req => {
            if (req.data['RESP'] === -1) {
                this.notifs.current.error_handler(req)
            } else if (req.data['RESP'] === 0) {
                this.notifs.current.add_notif('critical', 'Логин или почта уже зарегистрирована', 'Адрес электронной почты или логин уже зарегистрирован. Попробуйте войти через логин', 7500)
                // this.notifs.current.add_notif('critical', 'Логин или почта уже зарегистрирована', 'Адрес электронной почты или логин уже зарегистрирован. Попробуйте войти через логин или нажать кнопку "Я забыл пароль/логин"', 7500)
            } else {
                if (req.data['STATUS'] !== undefined) {
                    this.loginpage.current.style.display = 'none'
                    this.verifymailpage.current.style.display = 'block'
                }
            }
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
        this.registerbutton.current.disabled = false
    }

    componentDidMount() {
        if (canBeCheckedAcc) {
            this.acc_exists()
            canBeCheckedAcc = !canBeCheckedAcc
        }
    }

    acc_exists() {
        const cookies = new Cookies();

        if (!cookies.get('cursessid')) {
            return
        }

        APIpost("accgt/", {
            'cookiess': cookies.get('cursessid'),
            'ckksst': localStorage.getItem('sltcks')
        }, "BestAccounts").then(req => {
            if (req.data['RESP'] === -1) {
                this.notifs.current.error_handler(req)
            } else if (req.data['RESP'] === 0) {
                // this.notifs.current.add_notif('critical', 'Логин или почта уже зарегистрирована', 'Адрес электронной почты или логин уже зарегистрирован. Попробуйте войти через логин или нажать кнопку "Я забыл пароль/логин"', 7500)
            } else if (req.data['RESP'] === 1) {
                window.location.href = '/cabinet'
            }
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
    }

    switch_token() {
        this.tokenblock.current.style.display = "block"
        this.loginblock.current.style.display = "none"
        this.registerblock.current.style.display = "none"

        this.tokenblockbutton.current.style.display = "none"
        this.loginblockbutton.current.style.display = "block"
        this.registerblockbutton.current.style.display = "block"
    }

    switch_login() {
        this.tokenblock.current.style.display = "none"
        this.loginblock.current.style.display = "block"
        this.registerblock.current.style.display = "none"

        this.tokenblockbutton.current.style.display = "block"
        this.loginblockbutton.current.style.display = "none"
        this.registerblockbutton.current.style.display = "block"
    }

    switch_register() {
        this.tokenblock.current.style.display = "none"
        this.loginblock.current.style.display = "none"
        this.registerblock.current.style.display = "block"

        this.tokenblockbutton.current.style.display = "block"
        this.loginblockbutton.current.style.display = "block"
        this.registerblockbutton.current.style.display = "none"
    }

    pass_check() {
        const pass = this.passregpost.current.value

        if (pass.length < 8) {
            this.setState({
                passcondtext: '*Длина пароля должна быть 8 или более символов'
            })
            this.pass_valid = false
        } else if (pass.length >= 44) {
            this.setState({
                passcondtext: '*Длина пароля должна быть менее 44 символов'
            })
            this.pass_valid = false
        } else if (pass.toLowerCase() === pass) {
            this.setState({
                passcondtext: '*Пароль должен содержать хотя бы одну заглавную букву'
            })
            this.pass_valid = false
        } else if (!/^[a-zA-Z0-9]+$/.test(pass)) {
            this.setState({
                passcondtext: '*Пароль должен быть только из ASCII символов (латиницы, английских букв)'
            })
            this.pass_valid = false
        } else {
            this.pass_valid = true
            this.setState({
                passcondtext: ''
            })
        }
        this.form_valid()
    }

    render() {
        return (
            <>
                <div id="loginpage" ref={this.loginpage}>
                    <div className="loginblock">
                        <div id="typereg_switch">
                            <div id="unionin">
                                <button ref={this.tokenblockbutton} onClick={this.switch_token}
                                        className="switch_but">Токен
                                </button>
                                <button style={{display: "none"}} ref={this.loginblockbutton}
                                        onClick={this.switch_login} className="switch_but">Логин
                                </button>
                                <button ref={this.registerblockbutton} onClick={this.switch_register}
                                        className="switch_but">Регистрация
                                </button>
                            </div>
                        </div>
                        <div className="loginsubblock" ref={this.tokenblock} style={{display: "none"}}>
                            <h1>Авторизация токеном</h1>
                            <form id="token_auth">
                                <label>Токен</label>
                                <Token refr={this.tokentokenpost} placeholder="Токен"/>
                                <button ref={this.tokenbutton} onClick={this.token_auth} form='token_auth'
                                        className="auth_but">Войти
                                </button>
                            </form>
                        </div>
                        <div className="loginsubblock" ref={this.loginblock}>
                            <h1>Авторизация</h1>
                            <form id="password_auth">
                                <label>Логин</label>
                                <LoginInp onChange={this.login_valid} refr={this.loginloginpost} placeholder="Логин"/>
                                <label>Пароль</label>
                                <Password onChange={this.login_valid} refr={this.passloginpost} placeholder="Пароль"/>
                                <button ref={this.loginbutton} onClick={this.login} form='password_auth'
                                        className="auth_but">Войти
                                </button>
                            </form>
                        </div>
                        <div className="loginsubblock" style={{display: "none"}} ref={this.registerblock}>
                            <h1>Регистрация</h1>
                            <form id="register">
                                <label>Имя</label>
                                <Name autoComplete_="off" onChange={this.form_valid} refr={this.nameregpost}
                                      placeholder="Имя"/>
                                <label>Логин</label>
                                <LoginInp autoComplete_="off" onChange={this.form_valid} refr={this.loginregpost}
                                          placeholder="Логин"/>
                                <label>Пароль</label>
                                <Password autoComplete_="off" onChange={this.pass_check} refr={this.passregpost}
                                          placeholder="Пароль"/>
                                <span id='passcondtext' ref={this.passcond}>{this.state.passcondtext}</span>
                                <label>Почта</label>
                                <Mail onChange={this.form_valid} refr={this.mailregpost} placeholder="Почта"/>
                                <label>Рассылка новостей</label>
                                <h6>Обещаем присылать только интересные и важные новости</h6>
                                <Mailing autoComplete_="off" refr={this.mailingregpost} placeholder="Рассылка"/>
                                {/*<ComponentWithCaptcha ref={this.captcharef} />*/}
                                <button form='register' ref={this.registerbutton} onClick={this.postreg}
                                        className="auth_but">Зарегистрироваться
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div ref={this.verifymailpage} style={{display: "none"}} id="verifypage">
                    <div id="verify-content">
                        <h1 id="verify-title">На вашу почту отправлено письмо с <span
                            id="verify-submit">подтверждением</span></h1>
                        <h5>После подтверждения электронной почты ваш аккаунт будет создан</h5>
                        <h6 style={{margin: '4px'}}>Если вы не видите письмо, то возможно оно попало в спам</h6>
                    </div>
                </div>
            </>
        );
    }
}

export default Login;