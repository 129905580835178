import React from 'react';


function Mail(props) {
    return (
        <>
            <h6>В формате записи электронной почты (example123@mail.com)</h6>
            <input onChange={props.onChange} ref={props.refr} name="mail" maxLength="100" required type="email"
                   inputMode="email" placeholder={props.placeholder} autoComplete={props.autoComplete_?props.autoComplete_:'off'} />
        </>
    )
}

export default Mail;