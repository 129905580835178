import React, {Component} from 'react';
import APIpost from "../base"
import Cookies from "universal-cookie";
import chev_right from '../Assets/chev_right.png'
import {name_pattern} from "../config";
import logout_img from "../Assets/logout_button.png"


let canBeChecked = true


class Cabinet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            surname: '',
            email: '',
            cur_name: '',
            cur_surname: '',
            cur_email: '',
            mailing: true,
            dialog_opened: false,
            second_button_disabled: true,
            submit_button_disabled: false,
            edit_button_disabled: false,
        }

        this.name_inp = React.createRef()
        this.surname_inp = React.createRef()
        this.email_inp = React.createRef()
        this.submitbut = React.createRef()
        this.cancelbut = React.createRef()
        this.editbut = React.createRef()
        this.newtokenbut = React.createRef()

        this.dialog_recreate = React.createRef()
        this.notifs = props.notifs

        this.check_acc = this.check_acc.bind(this)
        this.recreate_token = this.recreate_token.bind(this)
        this.start_edit = this.start_edit.bind(this)
        this.end_edit = this.end_edit.bind(this)
        this.submit_edit = this.submit_edit.bind(this)
    }

    recreate_token() {
        const cookies = new Cookies()

        APIpost('rcat/', {
            "cookiess": cookies.get('cursessid'),
            "ckksst": localStorage.getItem('sltcks')
        }, 'BestAccounts').then(
            req => {
                if (req.data['RESP'] === -1) {
                    this.notifs.current.error_handler(req)
                } else if (req.data['RESP'] === 0) {
                    // this.notifs.current.add_notif('critical', `Ошибка ${req.data['STATUS']['code']}`, req.data['STATUS']['reasons']['russian'], 12500, 'bestdevelopment.work@gmail.com', "mailto:")
                } else if (req.data['RESP'] === 1) {
                    this.notifs.current.add_notif("success", 'Токен пересоздан', 'Токен успешно пересоздан и был выслан вам на почту', 10000)
                }
            }
        ).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })

        this.setState({
            dialog_opened: false
        })
    }

    check_acc() {
        const cookies = new Cookies();

        if (!cookies.get('cursessid')) {
            window.location.href = '/login'
        }

        APIpost("accgt/", {
            'cookiess': cookies.get('cursessid'),
            'ckksst': localStorage.getItem('sltcks')
        }, "BestAccounts").then(req => {
            if (req.data['RESP'] === -1) {
                this.notifs.current.error_handler(req)
            } else if (req.data['RESP'] === 0) {
                window.location.href = '/login'
            } else {
                if (req.data['acc_data'] === 0) {
                    window.location.href = '/login'
                } else {
                    this.setState({
                        name: req.data['acc_data'].name,
                        surname: req.data['acc_data'].surname,
                        email: req.data['acc_data'].email,
                        mailing: req.data['acc_data'].mailing,
                        login: req.data['acc_data'].login,

                        cur_name: req.data['acc_data'].name,
                        cur_surname: req.data['acc_data'].surname,
                        cur_email: req.data['acc_data'].email,
                        cur_mailing: req.data['acc_data'].mailing
                    })
                    this.name_inp.current.classList.remove('placeholder_text--line')
                    this.surname_inp.current.classList.remove('placeholder_text--line')
                    this.email_inp.current.classList.remove('placeholder_text--line')
                }
            }
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
    }

    start_edit() {
        this.setState({
            submit_button_disabled: false,
            second_button_disabled: false,
            edit_button_disabled: true,
        })
    }

    end_edit() {
        this.setState({
            second_button_disabled: true,
            edit_button_disabled: false,
            name: this.state.cur_name,
            surname: this.state.cur_surname,
            email: this.state.cur_email,
            mailing: this.state.cur_mailing,
        })
    }

    logout() {
        const cookies = new Cookies()

        APIpost('lgt/', {
            "cookiess": cookies.get('cursessid'),
            "ckksst": localStorage.getItem('sltcks')
        }, "BestAccounts").then(req => {
            if (req.data['RESP'] === -1) {
                this.notifs.current.error_handler(req)
            } else if (req.data['RESP'] === 0) {
                // this.notifs.current.add_notif('warning', ``, req.data['STATUS']['reasons']['russian'], 12500, 'bestdevelopment.work@gmail.com', "mailto:")
            } else {
                window.location.href = '/login'
            }
        }).catch(e => {
            if (e.response.data['RESP'] === -1) {
                this.notifs.current.error_handler(e.response)
            } else {
                this.notifs.current.unhandled_error_handler(e)
            }
        })
        window.location.href = '/login'
    }

    submit_edit() {
        this.setState({
            submit_button_disabled: true,
        })

        if (!this.name_inp.current.checkValidity() && this.surname_inp.current.checkValidity() && this.email_inp.current.checkValidity()) {
            this.notifs.current.add_notif("warning", "Некорректная форма", "Данные, введённые в поля некорректны или имеют неверный формат", 6500)
        } else {
            if (this.state.name === this.state.cur_name &&
                this.state.surname === this.state.cur_surname &&
                this.state.mailing === this.state.cur_mailing &&
                this.state.email === this.state.cur_email) {

                this.setState({
                    submit_button_disabled: false,
                })
                return
            }

            const cookies = new Cookies()

            APIpost('uaip/', {
                'name': this.state.name,
                'surname': this.state.surname,
                'mail': this.state.email,
                'mailing': this.state.mailing,
                "cookiess": cookies.get('cursessid'),
                "ckksst": localStorage.getItem('sltcks')
            }, "BestAccounts").then(req => {
                if (req.data['RESP'] === -1) {
                    this.notifs.current.error_handler(req)
                } else if (req.data['RESP'] === 0) {
                    this.notifs.current.add_notif('critical', "Адрес уже зарегистрирован", "Адрес электронной почты занят (уже зарегистрирован)", 8000)

                    this.setState({
                        email: this.state.cur_email
                    })

                    this.setState({
                        submit_button_disabled: false,
                    })
                } else {
                    if (req.data['STATUS']['code'] === "100-0002" || req.data['STATUS']['code'] === "100-0004") {
                        this.setState({
                            cur_name: this.state.name,
                            cur_surname: this.state.surname,
                            cur_mailing: this.state.mailing,
                        })
                        this.notifs.current.add_notif('success', 'Информация обновлена', `Теперь вы ${this.state.surname} ${this.state.name}, который ${this.state.mailing?'':'не'} подписан на рассылку`, 4500)
                    }
                    if (req.data['STATUS']['code'] === "100-0003" || req.data['STATUS']['code'] === "100-0004") {
                        this.notifs.current.add_notif('info', 'Требуется подтверждение почты', `Перейдите по ссылке в письме, отосланном на новую почту. Если вы сделали это случайно, просто проигнорируйте письмо`, 4500)
                    }

                    this.setState({
                        submit_button_disabled: false,
                    })
                }
            }).catch(e => {
                if (e.response.data['RESP'] === -1) {
                    this.notifs.current.error_handler(e.response)
                } else {
                    this.notifs.current.unhandled_error_handler(e)
                }

                this.setState({
                    submit_button_disabled: false,
                })
            })

            this.setState({
                second_button_disabled: true,
                edit_button_disabled: false,
            })
        }
    }

    componentDidMount() {
        if (canBeChecked) {
            this.check_acc()
            canBeChecked = !canBeChecked
        }
    }

    render() {
        return (
            <div id='cabinetpage'>
                <div className="cabinetblock">
                    <div id='cabinet_header'>
                        <h1 id='cabinettitle'>Личный кабинет</h1>
                        <button onClick={this.logout} id='logout_but'><img alt="Выход из аккаунта" src={logout_img} /></button>
                    </div>
                    <div className='cab_info_editable'>
                        <h5>Имя</h5>
                        <input autoComplete="off" pattern={name_pattern} readOnly={this.state.second_button_disabled} onChange={(e) =>
                            this.setState({name: e.target.value})
                        } ref={this.name_inp} value={this.state.name || ''} className='placeholder_text--line'
                               disabled={this.state.second_button_disabled} placeholder={this.state.name} name='name' />
                        <button onClick={() => {navigator.clipboard.writeText(this.name_inp.current.value); this.notifs.current.add_notif('info', 'Скопировано', 'Имя скопировано в буфер обмена', 1750)}}><img className='chevron_right' alt='Копировать имя' src={chev_right} /></button>
                    </div>
                    <div className='cab_info_editable'>
                        <h5>Фамилия</h5>
                        <input autoComplete="off" pattern={name_pattern} readOnly={this.state.second_button_disabled}
                               onChange={(e) => this.setState({surname: e.target.value})}
                            ref={this.surname_inp} value={this.state.surname || ''} className='placeholder_text--line'
                               disabled={this.state.second_button_disabled} placeholder={this.state.surname} name='surname' />
                        <button onClick={() => {navigator.clipboard.writeText(this.surname_inp.current.value); this.notifs.current.add_notif('info', 'Скопировано', 'Фамилия скопирована в буфер обмена', 1750)}}><img className='chevron_right' alt='Копировать фамилию' src={chev_right} /></button>
                    </div>
                    <div className='cab_info_editable'>
                        <h5>Почта</h5>
                        <input autoComplete="off" type="email" readOnly={this.state.second_button_disabled} onChange={(e) =>
                            this.setState({email: e.target.value})}
                               ref={this.email_inp} value={this.state.email || ''} className='placeholder_text--line'
                               disabled={this.state.second_button_disabled} placeholder={this.state.email} name='email' />
                        <button onClick={() => {navigator.clipboard.writeText(this.email_inp.current.value); this.notifs.current.add_notif('info', 'Скопировано', 'Почта скопирована в буфер обмена', 1750)}}><img className='chevron_right' alt='Копировать почту' src={chev_right} /></button>
                    </div>
                    <div className='cab_info_editable-small'>
                        <h5>Рассылка на почту</h5>
                        <input autoComplete="off" onChange={() => this.setState({mailing: !this.state.mailing})}
                               readOnly={this.state.second_button_disabled} type='checkbox' disabled={this.state.second_button_disabled}
                               checked={this.state.mailing} name='mailing' />
                    </div>
                    <div id='buttons_menu'>
                        <button style={{display: this.state.second_button_disabled?'none':'block'}} onClick={this.end_edit} type="button" ref={this.cancelbut} disabled={this.state.second_button_disabled} className="cancel_but cabinet_button">Отмена</button>
                        <button style={{display: this.state.second_button_disabled || this.state.submit_button_disabled?'none':'block'}} onClick={this.submit_edit} type="button" ref={this.submitbut} disabled={this.state.second_button_disabled || this.state.submit_button_disabled} className="submit_but cabinet_button">Подтвердить</button>
                        <button style={{display: this.state.edit_button_disabled?'none':'block'}} type="button" onClick={this.start_edit} ref={this.editbut} disabled={this.state.edit_button_disabled} className="edit_but cabinet_button">Редактировать</button>
                        <button type="button" ref={this.newtokenbut} onClick={() => {this.setState({dialog_opened: true})}} className='new_token_but cabinet_button'>Новый токен</button>
                    </div>
                </div>
                <dialog id='new_token_dialog' ref={this.dialog_recreate} open={this.state.dialog_opened}>
                    <h1>Вы уверены, что хотите пересоздать токен?</h1>
                    <h3>Вам придётся заново входить во все сервисы BestDevelopment</h3>
                    <hr width="450px" />
                    <div className="dialog_buttons">
                        <button onClick={() => {this.setState({dialog_opened: false})}} className="norecreate_button">Нет, закрыть окно</button>
                        <button onClick={this.recreate_token} className="recreate_button">Пересоздать токен</button>
                    </div>
                </dialog>
            </div>

        );
    }
}

export default Cabinet;